import { defineStore } from 'pinia'
import { httpClient } from '../helpers/axios-wrapper'
import { TableCustomization } from '../api/tableCustomizeService'

// Define interfaces for user and store state

interface TableCustomizationState {
  TableCustomizations: TableCustomization[] | null
}

export const useTableCustomizationStore = defineStore('tableCustomizations-store', {
  state: (): TableCustomizationState => {
    const data = localStorage.getItem('tableCustomizations')
    let tableCustomizationlst: TableCustomization[] | null = null

    try {
      tableCustomizationlst = data ? JSON.parse(data) : null
    } catch (error) {
      console.error('Failed to parse auth-user from localStorage', error)
    }

    return {
      TableCustomizations: tableCustomizationlst || null,
    }
  },
  actions: {
    async GetAndSaveTableCustomizations(email: string): Promise<void> {
      try {
        const res = await httpClient.post<TableCustomizationState>(`/table-settings`, { email })
        // Store user details and JWT in local storage
        localStorage.setItem('tableCustomizations', JSON.stringify(res))
      } catch (error) {
        console.error('Error in Save Table Customization:', error)
        // Handle login failure, maybe show a notification
      }
    },

    GetTableCustomization(tableName: string): TableCustomization | null {
      const customization = this.TableCustomizations?.find((x) => x.table_name === tableName)
      if (customization) {
        return {
          email: customization.email,
          id: customization.id,
          table_name: customization.table_name,
          settings: customization.settings,
        }
      } else {
        console.warn(`Table customization with table_name "${tableName}" not found.`)
        return null // Return null if no customization is found
      }
    },
    UpdateTableCustomizationState(setting: TableCustomization): void {
      const index = this.TableCustomizations?.findIndex((x) => x.table_name === setting.table_name)

      // console.log(this.TableCustomizations)
      if (index !== undefined && index !== -1 && this.TableCustomizations) {
        // Update the item at the found index
        this.TableCustomizations[index] = setting

        // Persist the updated TableCustomizations array to localStorage
        localStorage.setItem('tableCustomizations', JSON.stringify(this.TableCustomizations))
      } else {
        this.TableCustomizations!.push(setting)
        localStorage.setItem('tableCustomizations', JSON.stringify(this.TableCustomizations))
        console.info(`Added new table customization with table_name "${setting.table_name}".`)
      }
    },
  },
})
