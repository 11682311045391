import { defineStore } from 'pinia'
import { httpClient } from '../helpers/axios-wrapper'
import { router } from '../router/index'
import { useToast } from 'vuestic-ui'
import { AppSetting } from '../api/appsettingService'
import { useTableCustomizationStore } from './tablecustomization-store'

// Define interfaces for user and store state

interface User {
  name: string
  email: string
  roles: string[]
}

interface AuthState {
  access_token: string
  token_type: string
  expires_in: number
  user: User | null
  appSettings: AppSetting[]
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => {
    const storedAuth = localStorage.getItem('auth-user')
    let authUser: AuthState | null = null

    try {
      authUser = storedAuth ? JSON.parse(storedAuth) : null
    } catch (error) {
      console.error('Failed to parse auth-user from localStorage', error)
    }

    return {
      user: authUser?.user || null, // Safeguard against null values
      access_token: authUser?.access_token || '', // Default to empty string
      token_type: authUser?.token_type || '',
      expires_in: authUser?.expires_in || 0,
      appSettings: authUser?.appSettings || [],
    }
  },
  actions: {
    async login(email: string, password: string): Promise<void> {
      try {
        // /auth/login
        const { init } = useToast()

        const userres = await httpClient.post<AuthState>(`/login`, { email, password })
        // const userres = await httpClient.get<AuthState>(`${baseUrl}login`)
        // Update Pinia state
        this.user = userres.user
        this.access_token = userres.access_token
        this.expires_in = userres.expires_in
        this.token_type = userres.token_type
        this.appSettings = userres.appSettings

        // Store user details and JWT in local storage
        localStorage.setItem('auth-user', JSON.stringify(userres))

        // Redirect to previous URL or default to home page
        router.push('/dashboard')

        init({ message: "You've successfully logged in", color: 'success' })

        const authTableCustomizationStore = useTableCustomizationStore()

        authTableCustomizationStore.GetAndSaveTableCustomizations(email)
      } catch (error) {
        console.error('Login failed:', error)
        // Handle login failure, maybe show a notification
      }
    },
    logout(): void {
      this.user = null
      this.access_token = ''
      this.token_type = ''
      this.expires_in = 0
      this.appSettings = []
      localStorage.removeItem('auth-user')
      localStorage.removeItem('tableCustomizations')
      router.push('/login')
    },

    GetAuthState(): AuthState {
      return {
        user: this.user,
        access_token: this.access_token,
        token_type: this.token_type,
        expires_in: this.expires_in,
        appSettings: this.appSettings,
      }
    },
    changeBillSetting(setting: AppSetting) {
      const billSetting = this.appSettings.find((x) => x.key === 'billSetting')
      if (billSetting) {
        billSetting.value = setting.value
        this.appSettings = []
        this.appSettings.push(billSetting)
      } else {
        this.appSettings = []
        this.appSettings.push(setting)
      }
    },
  },
})
